.dataTable {

  &__header {
    display: flex;
    flex-wrap: wrap;

    .dataTable__cell {
      font-weight: 700;
      background-color: $primary;
      color: $white;

      &.dataTable__row_title {
        border-radius: 1.6rem 1.6rem 0 0;

        @include md-up {
          border-radius: 1.6rem 0 0 0;
        }
      }
      &.dataTable__row_data-last {
        
        @include md-up {
          border-radius: 0 1.6rem 0 0;
        }
      }
      &.dataTable__cell_prevData,
      &.dataTable__row_data-last {
        background: rgba($primary, 0.80);
      }
      &.dataTable__cell_currData {
        background: rgba($primary, 0.50);
      }
    }
  }

  &__row {
    display: flex;
    margin-bottom: 2px;
    flex-wrap: wrap;

    @include md-up {
      flex-wrap: nowrap;
    }

    &_title {
      width: 99%;

      @include md-up {
        width: calc(100% - (2 * 7.1rem) - 11.6rem);
      }
    }

    &_data {
      width: 33%;

      @include md-up {
        width: 7.1rem;
      }
    }

    &_data-last {
      width: 33%;

      @include md-up {
        width: 11.6rem;
      }
    }

    &_last {

      & .dataTable__row_title {

        @include md-up {
          border-radius: 0 0 0 1.6rem;
        }
      }

      & .dataTable__cell_prevData {
        border-radius: 0 0 0 1.6rem;

        @include md-up {
          border-radius: 0;
        }
      }
      
      & .dataTable__row_data-last {
        border-radius: 0 0 1.6rem 0;
      }
    }
  }

  &__cell {
    @include flex-center-center;
    font-size: 1.6rem;
    padding: $spc2 $spc3;
    background: $white;

    &.dataTable__cell_prevData,
    &.dataTable__row_data-last {
      background: $gray-100;

      @include md-up {
        background: $white;
      }
    }

    &.dataTable__cell_currData {
      background: rgba($primary,0.05);
    }
    &.dataTable__row_title {
      justify-content: flex-start;
    }
  }

  .dataTable__row_data-last {
    padding: $spc2 $spc2;
  }

  .plus-minus_data {
    width: calc(100% - 3rem);
  }
}