.content {

  &__inner {
    padding: $spc-inner-sm 0;

    @include md-up {
      padding: $spc5 0;
    }

    @include lg-up {
      padding: $spc5 $spc-inner-lg;
    }

    @include xl-up {
      padding: 0 $spc-inner-xl;
    }
  }

  &__wrapper {
    h1, h2, h3, h4, h5 {
      // font-size: $font-size-base;
      margin-top: 2.4rem;
      margin-bottom: 0.8rem;
    }

    .title__colored {
      h1, h2, h3, h4, h5 {
        color: $primary-2;
        font-weight: 900;
      }
    }
  }

  &__title {
    margin-bottom: $spc4;

    @include md-up {
      text-align: center;
    }

    @include xl-up {
      margin-bottom: $spc5;
    }
  }
}

.videoBlock {
  max-width: 144rem;
  margin: 0 auto;
  padding: 2.4rem;

  @include lg-up {
    padding: $spc6;
  }

  .videoBlock__image {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    img {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      object-position: center;
    }
  }

  video {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: center;
    border-radius: $card-corner;
  }

  .embedded__iframe {
    width: 100%;
    aspect-ratio: 16 / 9 ;
  }

  .play__button {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: $primary;
    @include squaresize(4.8rem);
    cursor: pointer;

    &.show {
      display: block;
    }
  }
}