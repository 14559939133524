.dataAndCharts {
  // background: $gradient-finance;

  &__inner {

    @include md-up {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $spc6;
    }

    @include lg-up {
      padding: 0 $spc-inner-lg;
    }
    @include xl-up {
      padding: 0 $spc-inner-xl;
    }

    &.dataAndCharts__horizontal {

      @include md-up {
        display: block;
      }
    }
  }

  &__cards {

    .kpi__card {
      // color: $white;
      border-top: 2px dotted $white;

      // @include md-up {
      //   margin-bottom: $spc6;
      // }
    }

    &.dataAndCharts__horizontal {

      &.twoCards {
        @include md-up {
          display: grid;
          gap: $spc6;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &.threeCards {
        @include md-up {
          display: grid;
          gap: $spc6;
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }

  &.bg-white,
  &.bg-lightBlue {
    .kpi__card {
      border-color: $primary;
    }
  }

  &__charts {

    .image img {
      width: 31.2rem;
      object-fit: cover;
      object-position: center;
    }

    &.dataAndCharts__horizontal {

      &.twoCards {
        @include md-up {
          display: grid;
          gap: $spc6;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &.threeCards {
        @include md-up {
          display: grid;
          gap: $spc6;
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }

  &__chart {
    border-top: 2px dotted $white;
    padding: $spc4 0;
  }

  &.bg-white,
  &.bg-lightBlue {
    .dataAndCharts__chart {
      border-color: $primary;
    }
  }

  .btn{
    max-width: none;

    @include md-up {
      min-width: fit-content;
    }
  }
}
