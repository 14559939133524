.footer {
  background: $primary;

  &__inner {
    max-width: 144rem;
    margin: 0 auto;
    padding: $spc5 $spc3;

    @include md-up {
      padding: $spc5 $spc6;
    }
    @include lg-up {
      padding: $spc5 $spc7;
    }
    @include xl-up {
      padding: $spc5 $spc6;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spc5;
  }

  &__social {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;

    &_link img {
      @include squaresize(2rem);
    }
  }

  &__row {
    padding-bottom: $spc2;
    border-bottom: 2px solid rgba(255, 255, 255, 0.30);

    a {
      font-size: 16px;
      font-weight: 700;
      line-height: 135%; /* 21.6px */
      text-transform: uppercase;
      color: $white;
      &:hover {
        color: $cta;
      }

      @include md-up {
        display: flex;
        align-items: center;
      }

      p {
        color: rgba(255, 255, 255, 0.60);
        text-transform: none;

        @include md-up {
          margin: 0;
          margin-left: $spc3;
        }
      }
    }
  }

  &__menu {
    padding-bottom: $spc5;

    @include md-up {
      display: flex;
      column-gap: $spc3;
      flex-wrap: wrap;
    }

    &_link {
      display: block;
      width: fit-content;
      margin-top: $spc2;
      font-size: 16px;
      font-weight: 400;
      line-height: 135%;
      color: $white;

      &:hover {
        color: $cta;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    row-gap:$spc5;

    @include md-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &_stamp {
      font-size: 12px;
      font-weight: 400;
      color: $white;
    }
  }

  &__legal {
    display: flex;
    flex-wrap: wrap;
    column-gap: $spc2;
    row-gap: $spc2;

    &_link {
      font-size: 12px;
      font-weight: 400;
      color: $white;

      &:hover {
        color: $cta;
      }
    }
  }
}