.twoColumnTable {

  &__inner {
    
    @include lg-up {
      padding: 0 $spc-inner-lg;
    }
    
    @include xl-up {
      padding: 0 $spc-inner-xl;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include xl-up {
      grid-template-columns: 3fr 2fr;
      gap: $spc6;
      margin-bottom: $spc6;

      &.row-reverse {
        grid-template-columns: 2fr 3fr;
      }
    }

    &_content {
      margin-bottom: $spc4;

      @include xl-up {
        margin-bottom: 0;
      }
    }

    &_table {
      margin-bottom: $spc4;

      @include md-up {
        margin-bottom: $spc6;
      }

      @include xl-up {
        margin-bottom: 0;
        order: -1;
      }

      &.row-reverse {
        @include xl-up {
          order: 1;
        }
      }
    }
  }
}