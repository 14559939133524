.quote {

  &__inner {
    padding: $spc-inner-sm 0;

    @include md-up {
      padding: $spc5 0;
    }

    @include lg-up {
      display: flex;
      align-items: center;
      padding: $spc5 $spc-inner-lg;
    }

    @include xl-up {
      padding: 0 $spc-inner-xl;
    }
  }

  &__image {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center;
    width: 16rem;
    border-radius: 50%;
    margin: 0 auto $spc3;

    @include lg-up {
      margin: 0 $spc3 0 0;
    }

    @include xl-up {
      width: 20rem;
      // margin-left: 20rem;
    }
  }

  &__text,
  &__text p {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 135%;
    text-align: center;

    // @include lg-up {
    //   text-align: left;
    // }
    @include xl-up {
      font-size: 2.4rem;
    }
  }

  &__sign {
    font-family: "SF Pro Rounded Regular";
    color: #147BD1;
    font-size: 64px;
    font-weight: 400;
    line-height: 135%; /* 86.4px */
  }

  figcaption {
    text-align: center;

    // @include lg-up {
    //   text-align: left;
    // }
  }
}