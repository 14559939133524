.homeHero {

  &__img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 3 / 4;

    @include md-up {
      aspect-ratio: 21 / 9;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 50%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
    bottom: 0rem;
    padding: $spc5 $spc3;

    @include md-up {
      padding: $spc5 $spc6;
      height: 100%;
      width: 50%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
    }

    @include md-up {
      padding: $spc5 $spc7;
    }

    @include xl-up {
      justify-content: center;
    }
  }

  &__title,
  &__title p {
    font-size: $h1-font-size;
    font-weight: 700;
    line-height: 120%; /* 48px */
    margin-bottom: 0;
    hyphens: auto;
    color: $white;

    @include md-up {
      hyphens: none;
    }

    @include xl-up {
      font-size: $h1-font-size-xl;
    }
  }
}



.hero {
  
  &__img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 3 / 4;

    @include md-up {
      aspect-ratio: 21 / 9;
    }
  }

  &__content {
    padding: $spc5 $spc3;

    @include md-up {
      padding: $spc5 $spc6;
    }

    @include lg-up {
      padding: $spc5 $spc7;
    }

    @include xl-up {
      padding: $spc5;

      &_inner {
        padding: 0 $spc-inner-xl;
      }
    }
  }

  &__title {
    hyphens: auto;

    @include md-up {
      hyphens: none;
    }
  }
}