// Normal font-family
@font-face {
  font-family: 'CeraRoundPro';
  src:
    url('fonts/CeraRoundPro-Regular.otf') format('opentype’');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Light font-family
@font-face {
  font-family: 'CeraRoundPro-Light';
  src:
    url('fonts/CeraRoundPro-Light.otf') format('opentype’');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Bold font-family
@font-face {
  font-family: 'CeraRoundPro-Bold';
  src:
    url('fonts/CeraRoundPro-Bold.otf') format('opentype’');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Rounded Regular";
  src: url("https://db.onlinewebfonts.com/t/ead4092f880f75ff0883ced1856b2c37.eot");
  src: url("https://db.onlinewebfonts.com/t/ead4092f880f75ff0883ced1856b2c37.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/ead4092f880f75ff0883ced1856b2c37.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/ead4092f880f75ff0883ced1856b2c37.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/ead4092f880f75ff0883ced1856b2c37.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/ead4092f880f75ff0883ced1856b2c37.svg#SF Pro Rounded Regular")format("svg");
}
