// Small devices
@mixin sm-up {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
// 768px + 
@mixin md-up {
  @media (min-width: #{$screen-md-min}) {
    $page-margin: $spc6;
    @content;
  }
}

// Large devices
// 1024px + 
@mixin lg-up {
  @media (min-width: #{$screen-lg-min}) {
    $page-margin: $spc7;
    @content;
  }
}

// XL  devices
// 1440px + 
@mixin xl-up {
  @media (min-width: #{$screen-xl-min}) {
    $page-margin: $spc6;
    @content;
  }
}

// XXL devices
// 1920px + 
@mixin xxl-up {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin squaresize($size) {
  width: $size;
  height: $size;
}

@mixin font($size, $weight, $line-height, $color: $black) {
  font-size: $size;
  font-weight: $weight; 
  line-height: $line-height;
  color: $color;
}

