.btn {
  position: relative;
  padding: 1.2rem 2.4rem;
  transition: all 0.5s ease;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  min-width: fit-content;
  max-width: fit-content;
  border-radius: 2.4rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  text-transform: uppercase;

  svg.icon {
    width: 2.4rem;
    height: 2.4rem;
  }

  &--default {
    background-color: transparent;
    border: 0.2rem solid $cta;
    color: $cta;

    &:hover {
      color: $cta-hover;
      border-color: $cta-hover;
    }
    &:active {
      border-color: $cta-active;
      color: $cta-active;
    }
  }

  &--primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: rgba($primary, 0.5);
    }

    &:active {
      background-color: $active-color;
    }
  }

  &--ghost {
    background-color: transparent;
    border: 0.2rem solid #CCD7E9;
    color: #CCD7E9;

    &:hover {
      border-color: $white;
      color: $white;
    }
  }

  &--light {
    background-color: transparent;
    border: 0.2rem solid $white;
    color: $white;

    &:hover {
      border-color: #CCD7E9;
      color: #CCD7E9;
    }
  }

  &--secondary {
    background-color: $white;
    color: $cta;

    &:hover {
      background-color: rgba($white, 0.8);
      color: $cta-hover;
    }
   
  }

  &.right {
    flex-direction: row-reverse;
  }

  &--inline {
    color: $cta;

    &:hover {
      color: $cta-hover;
      border-color: $cta-hover;
    }

    &:active {
      border-color: $cta-active;
      color: $cta-active;
    }
  }
}