.menuButton {

  position: relative;

  &:hover {
    cursor: pointer;

    .menuButton__submenu {
      max-height: 30rem;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.50);
    }
  }

  &.menuButton__active a {
    color: $primary-2;
  }

  &__wrapper {
    clip-path: inset(0 -10vw);
    max-height: 0px;
    max-width: 0px;
    transition: clip-path 0.1s ease-out, max-height 0.3s ease-out;
    transition-delay: 0s, 0s;
  }

  &__main {
    display: flex;
    padding: $spc3 $spc2;
    background: transparent;
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
    color: $primary;

    &:hover {
      color: $cta;
    }

    svg {
      @include squaresize(2.4rem);
      transform: rotate(90deg);
    }
  }

  &__submenu {
    position: absolute;
    top: 7rem;
    overflow: hidden;
    max-height: 0px;
    background: $white;
    z-index: 10;
    transition: all 0.3s linear;

  }

  &__sub {
    display: flex;
    padding: $spc2 $spc3;
    background: transparent;
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
    color: $primary;

    &:hover {
      color: $cta;
    }

    &_active {
      color: $cta;
    }
  }
}