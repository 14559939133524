.highlights {
  padding-right: 0;
  background: #FFF;

  &__inner {
    @include lg-up {
      padding: 0 0 0 $spc-inner-lg;
    }
    @include xl-up {
      padding: 0 0 0 $spc-inner-xl;
    }
  }

  .slider__item {
    color: $primary;

    &:hover {
      color: $primary-2;
    }
  }

  img {
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
  }

  // .swiper-wrapper {
  //   padding: $spc10 0 0;
  // }

  .swiper-pagination {
    top: -1.6rem;
    position: relative;
    padding: 1.6rem 0;

    &-bullet {
      background-color: $white;
      border: 0.1rem solid $cta;
      opacity: 1 !important;
      position: relative;
      z-index: 1;
      width: .8rem;
      height: .8rem;

    }
  }

  .swiper-pagination-bullet-active {
    background-color: $cta;
  }

  .swiper-button-wrapper {
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    margin: 0;
    padding: 0;

    svg {
      fill: $primary-2;
    }

    &:after {
      display: none;
    }
  }

  .swiper-button-next {
    @include squaresize(9.6rem);
  }

  .swiper-button-prev {
    @include squaresize(4.8rem);
  }

  .right-side-gradient {
    position: absolute;
    right: 0;
    top: 0;
    width: 2.4rem;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    z-index: 10;

    @include md-up {
      width: 16.4rem;
    }
    @include lg-up {
      width: 13.4rem;
    }
    @include xl-up {
      width: 16.4rem;
    }
  }

  &__title,
  .swiper-pagination,
  .swiper-button-wrapper {
    padding-right: 2.4rem;

    @include md-up {
      padding-right: 4.8rem;
    }
    @include lg-up {
      padding-right: calc(4.8rem + $spc-inner-lg);
    }
    @include xl-up {
      padding-right: calc(4.8rem + $spc-inner-xl);
    }
  }
}