.slider {
  max-width: 144rem;
  margin: 0 auto;

  &__inner {
    padding: $spc2 0;

    @include md-up {
      padding: $spc3 0;
    }
  }

  &__content {
    padding: $spc3 $spc3 0;
    color: $primary;

    @include xl-up {
      padding: $spc5 0 0;
    }
  }

  .swiper {
    padding: 3.2rem 0 4rem;

    @include md-up {
      padding: 3.2rem 0 4.8rem;
    }
  }

  &__item {

    img {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .swiper-slider-pagination {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    bottom: -1.6rem;
  }

  .swiper-pagination {

    &-bullet {
      background-color: $white;
      border: 0.1rem solid $cta;
      opacity: 1 !important;
      position: relative;
      z-index: 1;
      width: .8rem;
      height: .8rem;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $cta;
  }

  .swiper-button-disabled {
    opacity: 0.35;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include squaresize(4.8rem);
    @include flex-center-center;
    border-radius: 50%;
    background: $primary-2;
    top: 65rem;

    svg {
      @include squaresize(2.4rem);
      fill: $white;
    }

    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: calc(50% - 4.8rem - 0.4rem);
    right: auto;
  }

  .swiper-button-next {
    right: calc(50% - 4.8rem - 0.4rem);
    left: auto;

    svg {
      transform: rotate(-180deg);
    }
  }
}

.mapslider {

  // .swiper-map {
    overflow: hidden;
  // }

  .slider__item .map__slider_image {
    object-fit: contain;
  }


  .swiper__nav_btn {
    display: none;

    &-title {
      display: none;

      @include md-up {
        display: inline;
      }
    }
  }

  .swiper-mapslider-pagination {
    padding-bottom: 2.4rem;
  }

  .swiper-pagination-bullet {
    width: fit-content;
    height: fit-content;
    padding: 1.2rem 1.8rem;
    margin: 0 !important;
    margin-left: -2px !important;
    background: $white;
    border: 2px solid $primary-2;
    border-radius: 0;

    color: $primary-2;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;

    @include md-up {
      padding: 1.2rem 2.4rem;
    }

    &:first-of-type {
      border-radius: 2.4rem 0 0 2.4rem;
    }

    &:last-of-type {
      border-radius: 0 2.4rem 2.4rem 0;
    }

    svg {
      fill: $primary-2
    }

    &-active {
      background: $primary-2;
      color: $white;

      svg {
        fill: $white;
      }
    }
  }

  .swiper-mapslider-pagination {
    display: flex;
    justify-content: center;
  }
}