.linkedCards {
  background: $page-bg;

  &__inner {
    display: grid;
    grid-template-columns: repeat(1fr);
    gap: $spc1;

    @include md-up {
      grid-template-columns: repeat(2, 1fr);
      gap: $spc3;
    }

    @include lg-up {
      padding: 0 $spc-inner-lg;
    }

    @include xl-up {
      gap: $spc6;
      padding: 0 $spc-inner-xl;
    }
  }

  .teaserCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: $spc3;
    background: $blue;
    opacity: 0.8;
    border-radius: $card-corner;
    transition: all 0.2s linear;

    @include lg-up {
      padding: $spc4;
      padding-right: 8.6rem;
    }
    @include xl-up {
      padding: $spc5;
    }

    h2,
    p {
      color: rgba($white, 0.9);
    }

    &:hover {
      transform: translateY(-1.2rem);
      box-shadow: 0 48px 48px 0 rgba(4,47,84,.2), 0 24px 24px 0 rgba(4,47,84,.12), 0 12px 12px 0 rgba(4,47,84,.1), 0 6px 6px 0 rgba(4,47,84,.12), 0 3px 3px 0 rgba(0,0,0,.1);
      transition: all 0.2s linear;

      h2,
      p {
        color: $white;
      }
    }

    &.bg-blue {
      background: linear-gradient(96deg, $blue 0%, $blue 100%);
      opacity: 0.9;

      @include xl-up {
        &:hover {
          background: $gradient-company;
          opacity: 1;
        }
      }
    }

    &.bg-finance {
      background: linear-gradient(96deg, $finance 0%, $finance 100%);
      opacity: 0.9;

      @include xl-up {
        &:hover {
          background: $gradient-finance;
          opacity: 1;
        }
      }
    }

    &.bg-market {
      background: linear-gradient(96deg, $market 0%, $market 100%);
      opacity: 0.9;

      @include xl-up {
        &:hover {
          background: $gradient-market;
          opacity: 1;
        }
      }
    }

    &.bg-summaries {
      background: linear-gradient(96deg, $summaries 0%, $summaries 100%);
      opacity: 0.9;

      @include xl-up {
        &:hover {
          background: $gradient-summaries;
          opacity: 1;
        }
      }
    }

    &.bg-sustainability {
      background: linear-gradient(96deg, $sustainability 0%, $sustainability 100%);
      opacity: 0.9;

      @include xl-up {
        &:hover {
          background: $gradient-sustainability;
          opacity: 1;
        }
      }
    }

    &.bg-knowledge {
      background: linear-gradient(96deg, $knowledge 0%, $knowledge 100%);
      opacity: 0.9;

      @include xl-up {
        &:hover {
          background: $gradient-knowledge;
          opacity: 1;
        }
      }
    }
  }
}