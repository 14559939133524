.download {

  &__outher {
    @include lg-up {
      padding: 0 $spc-inner-lg;
    }
    @include xl-up {
      padding: 0 $spc-inner-xl;
    }

    &_button {
      text-align: center;
      margin-top: $spc3;

      a {
        color: $primary;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 144%;
      }
      
      svg {
        @include squaresize(1.8rem);
        fill: $primary-2;
        margin-left: $spc1;
      }
    }
  }

  &__inner {
    padding: $spc4;
    // background: linear-gradient(96deg, #147BD1 0%, #00A3AD 100%);
    background: $blue;

    @include xl-up {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__buttons {
    gap: $spc3;

    .btn {
      max-width: none;

      @include md-up {
        width: 50%;
      }

      .icon {
        width: 2.4rem;
        height: 2.4rem;
        fill: #147BD1;
      }
    }
  }
}

.downloadMultiline {
  background: $page-bg;

  &__inner {

    @include lg-up {
      padding: 0 $spc-inner-lg;
    }
    @include xl-up {
      padding: 0 $spc-inner-xl;
    }
  }

  &__buttons {

    &_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &_item {
      justify-content: space-between;
      align-items: center;
      column-gap: $spc2;
      padding: 1.3rem 0;
      border-bottom: 2px solid rgba($primary, 0.10);

      @include md-up {
        width: 50%;
      }
      @include xl-up {
        width: 80%;
      }

      &-title{
        font-size: 16px;
        font-weight: 700;
        line-height: 120%; /* 19.2px */
        text-transform: uppercase;
        color: $primary-2;
      }

      &-data {
        color: $primary;
        font-size: 18px;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.36px;
        text-transform: uppercase;
      }

      svg {
        fill: $primary-2;
      }
    }
  }
}