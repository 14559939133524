.kpi {

  &__inner {
    padding: $spc-inner-sm 0;

    @include md-up {
      padding: $spc6 0;
    }

    @include xl-up {
      padding: 0;
    }
  }

  &__img {
    aspect-ratio: 4 / 3;
    object-fit: cover;

    @include xl-up {
      aspect-ratio: 21 / 9;
    }
  }

  &__description {

    @include lg-up {
      padding: 0 $spc-inner-lg;
    }

    @include xl-up {
      padding: 0 $spc-inner-xl;
    }
  }

  &__cards {

    @include md-up {

      &.threeCards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: $spc3;
      }

      &.twoCards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spc3;
      }
      
      &.threeCards,
      &.twoCards {
        @include xl-up {
          gap: $spc6;
        }
      }
    }

    @include lg-up {
      padding: 0 $spc-inner-lg;
    }

    @include xl-up {
      padding: 0 $spc-inner-xl;
    }
  }

  .btn {
    display: block;margin: 0 auto;
  }

  
}

.kpi__card {
  border-top: 2px dotted $black;
  transition: all 3s linear;
  cursor: pointer;


  .shortText {
    max-width: calc(100% - 9.6rem);
  }

  &_withBack {
    height: 36rem;
    overflow: hidden;
    transform: translateY(0rem);

    & .kpi__card_inner {
      height: 36rem;
    }

    & .kpi__card_subdata {

      min-height: 5.2rem;

      @include xl-up { 
        min-height: 10.2rem;
      }
     
    }

    @include xl-up {
      &:hover {
        .kpi__card_inner,
        .kpi__card_outer {
          transform: translateY(-36rem);
        }
      }
    }

    &.card-slide {

      .kpi__card_inner,
      .kpi__card_outer {
        transform: translateY(-36rem);
      }
    }
  }

  &_inner {
    position: relative;
    padding: $spc4 0;
    transition: all 0.5s linear;
  
    @include md-up {
      margin-bottom: 0;
    }
  }

  &_outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spc4 0;
    height: 36rem;
    font-size: 1.6rem;
    transition: all 0.5s linear;

    & p {
      font-size: 1.6rem;
    }
  }

  &_data {
    font-size: 6.4rem;
    font-weight: 900;
    line-height: 100%;

    span {
      font-size: 4.8rem;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 1.6rem auto 0;
    background-color: $cta;
    width: $spc8;
    height: $spc4;
  }

  .kpi__card_icon {
    position: absolute;
    top: $spc4;
    right: 0;
    @include squaresize(6.4rem);

    @include xl-up {
      @include squaresize(9.6rem);
    }
  }
}